"use client";

import { ColorProjects } from "@/lib/data";
import { RoutesEnum } from "@/lib/routes";
import { usePathname } from "next/navigation";
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type ColorContextProviderProps = {
  children: ReactNode;
};

type ColorContext = {
  currentColor: ColorProjects;
  setCurrentColor: Dispatch<SetStateAction<ColorProjects>>;
};

export const ColorContext = createContext<ColorContext | null>(null);

export function ColorContextProvider({ children }: ColorContextProviderProps) {
  const [currentColor, setCurrentColor] = useState<ColorProjects>(
    ColorProjects.CLR_MAIN
  );
  const pathname = usePathname();

  useEffect(() => {
    function customColor() {
      let newColor = ColorProjects.CLR_MAIN;

      switch (true) {
        case pathname === RoutesEnum.MAIN ||
          pathname === `/${RoutesEnum.ALL_PROJECTS}`:
          newColor = ColorProjects.CLR_MAIN;
          break;
        case pathname.includes(RoutesEnum.MTS_CODEFEST):
          newColor = ColorProjects.CLR_CODEFEST;
          break;
        case pathname.includes(RoutesEnum.MTS_JUNIOR_MOSKVARIUM):
          newColor = ColorProjects.CLR_MOSKVARIUM;
          break;
        case pathname.includes(RoutesEnum.SPACIBO_ARTLIFE_FEST):
          newColor = ColorProjects.CLR_SPACIBO;
          break;
        case pathname.includes(RoutesEnum.OKKO):
          newColor = ColorProjects.CLR_OKKO;
          break;
        case pathname.includes(RoutesEnum.GAZ_NN):
          newColor = ColorProjects.CLR_GAZNN;
          break;
        case pathname.includes(RoutesEnum.SQWOZBAB):
          newColor = ColorProjects.CLR_SQWOZBAB;
          break;
        case pathname.includes(RoutesEnum.MIRRPL_DP):
          newColor = ColorProjects.CLR_MIRRPL_DP;
          break;
        default:
          newColor = ColorProjects.CLR_MAIN;
      }

      document.documentElement.style.setProperty("--clr-current", newColor);
      setCurrentColor(newColor);
    }

    customColor();
  }, [pathname]);

  return (
    <ColorContext.Provider
      value={{
        currentColor,
        setCurrentColor,
      }}
    >
      {children}
    </ColorContext.Provider>
  );
}

export function useColorContext() {
  const context = useContext(ColorContext);

  if (!context) {
    throw new Error(
      "useColorContext must be used within a ColorContextProvider"
    );
  }
  return context;
}
