"use client";

import greenArrow from "@/images/cursor.svg";
import { RoutesEnum } from "@/lib/routes";
import Image from "next/image";
import Link from "next/link";
import {
  type BaseSyntheticEvent,
  type ComponentPropsWithRef,
  type ReactNode,
  useState,
} from "react";
import { useColorContext } from "src/context/color-context";
import { useCursorVariantContext } from "src/context/cursor-variant-context";
import styles from "./buttons.module.css";

interface DiscussButtonProps extends ComponentPropsWithRef<"a"> {
  styleProps?: Record<string, string>;
}

interface NextProjectButtonProps {
  href: string;
  titleOne: ReactNode;
  titleTwo: string;
}

export function DiscussButton({ children, className }: DiscussButtonProps) {
  const [isHovered, setHovered] = useState<boolean>(false);
  const { currentColor } = useColorContext();

  return (
    <Link
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        border: `0.125rem solid ${currentColor}`,
        backgroundColor: isHovered ? currentColor : "transparent",
      }}
      scroll={false}
      className={className}
      href={"?discuss=opened"}
      aria-label="discuss project"
    >
      {children}
    </Link>
  );
}

export function ArrowButton({
  left,
  onClick,
}: {
  left?: boolean;
  onClick: (e: BaseSyntheticEvent<MouseEvent, EventTarget>) => void;
}) {
  return (
    <button type="button" className={styles.arrowButton} onClick={onClick}>
      <svg
        role="img"
        aria-label={left ? "move left" : "move right"}
        className={left ? styles.arrowButtonLeft : styles.arrowButtonRight}
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="80"
          height="80"
          rx="40"
          transform="matrix(-1 0 0 1 80 0)"
          fill="#24282A"
        />

        {left && (
          <path
            d="M45.4062 53.8606L33.9962 42.4506C32.6487 41.1031 32.6487 38.8981 33.9962 37.5506L45.4062 26.1406"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
        {!left && (
          <path
            d="M34.5938 53.8606L46.0038 42.4506C47.3513 41.1031 47.3513 38.8981 46.0038 37.5506L34.5938 26.1406"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
    </button>
  );
}

export function GoToProjectsButton() {
  return (
    <Link
      href={`/${RoutesEnum.ALL_PROJECTS}`}
      className={styles.goToProjectsButton}
      aria-label="go to all projects"
    >
      <span>Все проекты</span>
      <Image className={styles.greenArrow} src={greenArrow} alt="" />
    </Link>
  );
}

export function NextProjectButton({
  href,
  titleOne,
  titleTwo,
}: NextProjectButtonProps) {
  const { linkEnter, linkLeave } = useCursorVariantContext();

  return (
    <Link
      href={href}
      onMouseEnter={() => linkEnter()}
      onMouseLeave={() => linkLeave()}
      onClick={() => linkLeave()}
      {...linkLeave}
      prefetch={false}
      aria-label="next project"
    >
      <div className={styles.nextProjectButton}>
        <span>Следующий проект</span>
        <h2>
          {titleOne} <br />
          <span>{titleTwo}</span>
        </h2>
      </div>
    </Link>
  );
}
